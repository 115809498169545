import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const teamMembers = [
  {
    name: 'Michael Lutz',
    role: 'Director and Teaching Staff',
    description: 'Michael conducts Aviation-NLP research for NASA Ames, has four AI publications, and works at world-class RL labs at Berkeley.',
    imageSrc: require('./../../assets/images/big mike.jpg'),
    imageAlt: 'Michael Lutz',
  },
  {
    name: 'Saurav Kumar',
    role: 'Director and Teaching Staff',
    description: 'Saurav researched distributed AI at a Stanford Medicine lab, wrote RL algorithms used in AMD chips, and goes to UIUC for CS and Physics.',
    imageSrc: require('./../../assets/images/saurav.jpg'),
    imageAlt: 'Saurav Kumar',
  },
  {
    name: 'Arjun Rajaram',
    role: 'Director and Teaching Staff',
    description: 'Arjun taught an ML course to 26k online students, worked in UCSC labs on Biomedical Image Analysis, and cofounded the Student Machine Learning Coalition.',
    imageSrc: require('./../../assets/images/arjun.jpeg'),
    imageAlt: 'Arjun Rajaram',
  },
  {
    name: 'Amisha Kumar',
    role: 'Research Mentor',
    description: 'A graduate of Case Western, Amisha is currently attending med school. She conducts bio and medical research with Nobel Laureates at Stanford and Harvard.',
    imageSrc: require('./../../assets/images/amisha.jpg'),
    imageAlt: 'Amisha Kumar',
  },
  {
    name: 'Fatemah Sharshatehrani',
    role: 'Research Mentor',
    description: 'Fatemeh Sarshartehrani is a Ph.D. student at Virginia Tech, researching empathy within human-robot interaction. Fatemeh is deeply passionate about teaching and finds fulfillment in sharing knowledge and empowering others.',
    imageSrc: require('./../../assets/images/fatemah.JPG'),
    imageAlt: 'Fatemah Sharshatehrani',
  },
  {
    name: 'Rishi Jain',
    role: 'Research Mentor',
    description: 'Rishi is a computer science student at Berkeley focused on AI research. His current focus includes reinforcement learning for task generalization, parameter-efficient tuning of foundational models, and medical speech science.',
    imageSrc: require('./../../assets/images/rishi.jpg'),
    imageAlt: 'Rishi Jain',
  },
  {
    name: 'Anushka Mukhopadhyay',
    role: 'Research Mentor',
    description: 'Anushka studies EECS at UC Berkeley. She has used ML to find natural disasters with NLP, create a suicide prevention app, and classify recyclables. She is passionate about increasing diversity in ML.',
    imageSrc: require('./../../assets/images/anushka_mentor.jpg'),
    imageAlt: 'Anushka Mukhopadhyay',
  },
  {
    name: 'Salar Cheema',
    role: 'Research Mentor',
    description: 'Salar studies CS and Statistics at UIUC. He has experience working at Amazon, teaching competitive math, and has won awards for his robotic AI systems.',
    imageSrc: require('./../../assets/images/salar.jpeg'),
    imageAlt: 'Salar Cheema',
  },
  {
    name: 'Nishk Patel',
    role: 'Research Mentor',
    description: 'Nishk studies Computer Science and Statistics at UIUC. At the National Center for Supercomputing Applications he conducts machine learning and geospatial research to help humanitarian aid.',
    imageSrc: require('./../../assets/images/nishk.jpeg'),
    imageAlt: 'Nishk Patel',
  },
  {
    name: 'Kinjal Chaudhari',
    role: 'Research Mentor',
    description: 'Kinjal conducts award winning algorithmic math research and programs physics simulations at some of the most prestigious labs in the world. She studies CS & Math at UIUC.',
    imageSrc: require('./../../assets/images/kinjal.jpeg'),
    imageAlt: 'Kinjal Chaudhari',
  },
  {
    name: 'Rithvik Vanga',
    role: 'Research Mentor',
    description: 'Rithvik studies Computer Science and Business at the University of Michigan. He has been a leader for his Robotics team, winning State and qualifying for Worlds 5 years in a row.',
    imageSrc: require('./../../assets/images/rith.jpg'),
    imageAlt: 'Rithvik Vanga',
  },
  {
    name: 'Nishkal Hundia',
    role: 'AI Fellow',
    description: 'Nishkal studies AI at the University of Maryland. A part of BLAST AI\'s 2022 cohort, he co-first authored a research paper on Genotype Imputation - his model beat accuracies of Stanford PhDs.',
    imageSrc: require('./../../assets/images/nishkal.jpg'),
    imageAlt: 'Nishkal Hundia',
  },
  // {
  //   name: 'Felipe Caceres-Fernandez',
  //   role: 'AI Fellow',
  //   description: 'Felipe designed mechanisms for a world class FRC robotics team, founded his own engineering outreach organization, and will be attending Carnegie Mellon University in the fall of 2024.',
  //   imageSrc: require('./../../assets/images/felipe.jpg'),
  //   imageAlt: 'Felipe Caceres-Fernandez',
  // },
];

class Team extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'team section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'team-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: 'Meet The Team',
      // paragraph: 'BLAST is led by researchers and industry profressionals from top CS universities. We know how to help students conduct research.'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            {/* <SectionHeader data={sectionHeader} className="center-content reveal-from-bottom" /> */}
            <div className={tilesClasses}>

            <div className={tilesClasses}>
              {teamMembers.map((member, index) => (
                <div key={index} className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay={index * 150}>
                  <div className="tiles-item-inner">
                    <div className="team-item-header">
                      <div className="team-item-image mb-24 rounded-full">
                        <Image
                          src={member.imageSrc}
                          alt={member.imageAlt}
                          width={180}
                          height={180}
                          className="rounded-full"
                          />
                      </div>
                    </div>
                    <div className="team-item-content">
                      <h5 className="team-item-name mt-0 mb-4">
                        {member.name}
                      </h5>
                      <div className="team-item-role text-xs fw-600 mb-8">
                        {member.role}
                      </div>
                      <p className="m-0 text-sm">
                        {member.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
             
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Team.propTypes = propTypes;
Team.defaultProps = defaultProps;

export default Team;