import React from 'react';
import classNames from 'classnames';
import Confetti from 'react-confetti';
import GenericSection from '../components/sections/GenericSection';
import Button from '../components/elements/Button';

class AcceptancePage extends React.Component {
  state = {
    showConfetti: false,
    name: ""
  };

  async componentDidMount() {
    const { userId } = this.props.match.params;
    let curr = await this.checkValidUUID(userId);
    this.setState({ showConfetti: true , name:curr, userId: userId});
    // console.log("CURR", curr)
  }

  async checkValidUUID(uuidString) {
    let uid = uuidString.replace(".","");
    let url = "https://api.airtable.com/v0/appiZzCBMSc67sWMC/tblv7GYe8q0XVk6IV"
    let options = {
      method: 'GET',
      headers: {
          Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_KEY}`
      }
    };
    var name = ""
    try {
      let fetching = true;
      while (fetching) {
        let response = await fetch(url, options);
        let data = await response.json();
        const records = data.records;
        records.map(record => {
          if (record.fields.UUID === uid) {
            name = record.fields.name;
            fetching = true;
            return name
          } 
        })
        if (name != ""){
          return name
        }
        if (data.offset != "") {
            url += `?offset=${data.offset}`;
        } else {
            fetching = false;
        }
      }
    } catch (error) {
      this.props.history.push('/');
      console.log("ERROR:", error);
    }
    // console.log("FOUND2:", name);
    this.props.history.push('/');
    return name
  }
  capitalizeWords(inputString) {
    if (!inputString) {
      return "";
    }
  
    const words = inputString.split(" ");
    const ret = words.map(word => {
      const lowercase = word.toLowerCase();
      const firstLetter = lowercase.charAt(0).toUpperCase();
      return `${firstLetter}${lowercase.slice(1)}`;
    });
  
    return ret.join(" ");
  }
  
  render() {
  
    // Converting userId into the actual name
    // Input: all upercase letters, rotated 11
    // Output: rotate backwards 11
    

    const { showConfetti, name, userId } = this.state;
    var { fullName } = "";
    fullName = name;
    var redirectLink = `/commit/${userId}`;

    if (fullName !== "") {
        return (
            <div className="container mt-20 mx-auto px-4 md:px-0">
                <section className="flex justify-center items-center min-h-screen mt-20">
                <div className="bg-white border border-gray-400 p-8 md:p-16 pt-64 md:pt-64 md:pl-32 md:pr-32 mx-auto max-w-3xl">
                    {showConfetti && (
                    <Confetti
                        recycle={false}
                        numberOfPieces={800}
                        width={window.innerWidth}
                        height={window.innerHeight}
                    />
                    )}
                    <div className="">
                    <h2 className="px-4 md:px-20 pb-32 pt-32">Congratulations {fullName}!</h2>
                    </div>
                    <p className="px-4 md:px-20 text-sm md:text-base">
                    {/* <div className="bg-white border border-gray-400 p-16 pt-64 pl-128 pr-128 mx-auto max-w-3xl text-center">
                        {showConfetti && (
                        <Confetti
                            recycle={false}
                            numberOfPieces={800}
                            width={window.innerWidth}
                            height={window.innerHeight}
                        />
                        )}
                        <div className="pt-50">
                        <h2 className="px-20 pb-32 pt-32">Congratulations {fullName}!</h2>
                        </div>
                        <p className="px-20"> */}
                        We are honored to offer you admission to the BLAST AI 2024 Research Cohort!
                        <br />
                        <br />
                        Your application left our admissions staff inspired by your exceptional maturity, intellect, and passion. Among numerous applicants across 16 countries, your distinct history of academic and personal accomplishment stood out as unique. We are absolutely convinced that you possess the intellectual vitality required to conduct research of the highest caliber, and we are excited to see how you will contribute to the Summer 2024 Cohort. You are an exceptional match for BLAST, and we sincerely hope you will join us this summer!
                        <br />
                        <br />
                        On this page you can find: (1) a copy of a program overview, which outlines the schedule for the research program as well as the cost of attendance; and (2) a Terms of Service agreement.
                        <br />
                        <br />
                        BLAST is dedicated to expanding access to AI research, which is why we price the program far less than comparable programs. If you indicated financial need on your application, you will receive a link to the scholarship in the coming days. We will finalize scholarship packages and get back to you by early May, but you may fill out the commit form in the meantime.
                        <br />
                        <br />
                        Next Steps:
                        <br />
                        <ol className="list-decimal pl-6 mb-4">
                        <li>
                            Please fill out{' '}
                            <a
                            href={redirectLink}
                            >
                            this form
                            </a>{' '}
                            with your decision to commit before 6/5/2024.
                        </li>
                    <li>
                      If you are applying for financial aid, you can disregard the above deadline and keep an eye out for emails regarding scholarships. Please fill out this form {' '}
                      <a
                        href="https://form.jotform.com/240911528157153"
                      >
                        this form
                      </a>{' '}
                      to let us know you would like aid.
                    </li>
    
                            <li>
                            Read through the documents on this page completely.
                            </li>
                        </ol>
                        <br />
                        You may pay your tuition fees in the form above. If you would prefer an invoice, payment by check, or any other payment options, please email us at info@blastai.org.
                        <br />
                        <br />
                        Once again, congratulations! We are excited to guide you through your journey of learning and exploration this summer!
                    </p>
                </div>
                </section>
    
                <GenericSection className="center-content">
                    <div className="container">
                    <div className="flex justify-center">
                        <Button
                        color="primary" 
                        target="_blank" 
                        href="https://blastai.org/research-overview.pdf" 
                        wideMobile
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            window.open('https://blastai.org/research-overview.pdf', '_blank', 'noopener noreferrer');
                        }}
                        className="mr-32"
                        >
                        Program Overview
                        </Button>
                        
                        <Button 
                        color="primary" 
                        target="_blank" 
                        href="https://blastai.org/tos.pdf" 
                        wideMobile
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            window.open('https://blastai.org/tos.pdf', '_blank', 'noopener noreferrer');
                        }}
                        >
                        Terms of Service
                        </Button>
                    </div>
                    </div>
                </GenericSection>
    
            </div>
        );
    } else {
        // show loading
        return (
            <div className="container mt-20 mx-auto px-4 md:px-0">
                <section className="flex justify-center items-center min-h-screen mt-20">
                <div className="p-8 md:p-16 pt-64 md:pt-64 md:pl-32 md:pr-32 mx-auto max-w-3xl text-center">
                    <p>Loading...</p>
                </div>
                </section>
            </div>
        );
    }
  }   
}

export default AcceptancePage;