import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../../utils/SectionProps';
import Button from '../../elements/Button';
import Image from '../../elements/Image';

const programData = [
    {
      title: "Biology",
      description: "During the first two weeks, you will learn the fundamentals in a structured ML bootcamp.",
      imgSrc: require('./../../../assets/images/bio.jpg'),
      link: "/research",
      dateInfo: "June 16, 2024 - August 11, 2024"
    },
    {
        title: "Politics",
        description: "Simulate polling with LLM web agents. Use sentiment analysis and ",
        imgSrc: require('./../../../assets/images/polling-track.jpg'),
        link: "/research",
        dateInfo: "June 16, 2024 - August 11, 2024"
    },
    {
        title: "Physics",
        description: "Simulate gravitational effects to solve the 3-body problem using high dimensional ML.",
        imgSrc: require('./../../../assets/images/physics.jpg'),
        link: "/research",
        dateInfo: "June 16, 2024 - August 11, 2024"
    },
    {
      title: "Finance",
      description: "Forecast stock prices using various statistical ML models.",
      imgSrc: require('./../../../assets/images/finance.jpg'),
      link: "/research",
      dateInfo: "June 16, 2024 - August 11, 2024"
    },
    {
      title: "Natural Language Processing",
      description: "Build your own ChatBot! Write prompting algorithms and utilize cutting edge LLM research.",
      imgSrc: require('./../../../assets/images/Chat.jpg'),
      link: "/launchpad",
      dateInfo: "2 weeks of project-based learning"
    },
    {
      title: "Computer Vision",
      description: "Leverage modern CV techniques for object detection to support autonomous vehicle efforts.",
      imgSrc: require('./../../../assets/images/CV.jpg'),
      link: "/research",
      dateInfo: "June 16, 2024 - August 11, 2024"
    },
    {
      title: "Reinforcement Learning",
      description: "Beat video games using AI! Teach your model to develop winning strategies using statistical techniques.",
      imgSrc: require('./../../../assets/images/snake.gif'),
      link: "/research",
      dateInfo: "June 16, 2024 - August 11, 2024"
    },
    // Add more programs as needed
  ];

  
const propTypes = {
  ...SectionTilesProps.types
};

const defaultProps = {
  ...SectionTilesProps.defaults
};
class ProgramOfferings extends React.Component {
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const heroBackground = document.querySelector('.program-offering-background');
    const offset = window.pageYOffset;
    if (heroBackground) {
      heroBackground.style.backgroundPositionY = `${offset * -.5}px`;
    }
  }


  render() {
    // Destructure props and state
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    // Class name logic
    const outerClasses = classNames(
    //   'program offering section',
    //   'program-offering-background',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'testimonial-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap-2', 'pb-7',
      pushLeft && 'push-left'
    );

    return (
      <section {...props} className={outerClasses}>
        <div className="pt-1 container-lg">
          <div className={innerClasses}>
            <div className="mb-8">
              <h2 className="text-3xl font-bold text-center mb-4">Build your ML portfolio</h2>
              <p className="text-lg text-center mb-6">Pick one of our several launchpad tracks to build your own personalized capstone project.</p>
            </div>
            <div className={tilesClasses}>
              {programData.map((program, index) => (
                <div className="tiles-item" data-reveal-container=".tiles-wrap">
                  <div className="tiles-item-inner has-shadow">
                    <div className="testimonial-item-image center-content pb-2">
                      <img
                        src={program.imgSrc}
                        alt={program.title}
                        style={{ width: '250px', height: '250px', objectFit: 'cover', objectPosition: 'center' }}
                         />
                    </div>
                    <div className="testimonial-item-header text-center text-black ">
                      <p className="text-lg mb-0 fw-700">
                        {program.title}
                      </p>
                    </div>
                    <div className="testimonial-item-content pb-3">
                      <p className="text-sm mb-0">
                        {program.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            
          </div>
        </div>
      </section>
    );
  }
}

ProgramOfferings.propTypes = propTypes;
ProgramOfferings.defaultProps = defaultProps;

export default ProgramOfferings;