import React from 'react';
import { withRouter, Switch, Route, useSearchParams, BrowserRouter} from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import './tailwind.css';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';
import LayoutAlternative from './layouts/LayoutAlternative';
import LayoutAlt2 from './layouts/LayoutAlt2';
import LayoutSignin from './layouts/LayoutSignin';

// Views 
import Home from './views/Home';
import Curriculum from './views/Bootcamp';
import Secondary from './views/Secondary';
import Symposium from './views/Research';
import AcceptanceLaunchpad from './views/Acceptance-Launchpad';
import AcceptanceResearch from './views/Acceptance-Research';
import RejectResearch from './views/Reject-Research';
// import RejectResearch from './views/Reject-Research';
import AcceptanceBootcamp from './views/Acceptance-Bootcamp';
import Login from './views/temp';
import Team from './views/Team';
import pr from './views/PR';
import ResearchApplication from './views/ResearchApplication';
import ResearchAcceptWithPayment from './views/ResearchAcceptWithPayment';
import LaunchpadApplication from './views/LaunchpadApplication';
import LayoutPlain from './layouts/LayoutPlain';
import LayoutPlainHeader from './layouts/LayoutPlainHeader';
import ProgramRouter from './views/ProgramRouter';
import ResearchAcceptPage from './views/ResearchAccept';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedInUser: null,
      fullName: ''
    };

  }
  setFullName = (fullName) => {
    this.setState({ fullName });
  }

  componentDidMount() {
    document.body.classList.add('is-loaded')
    this.refs.scrollReveal.init();
    // this.scrollRevealRef.current.init();
    // this.init();

  }

  // Route change
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.refs.scrollReveal.init();
      // this.scrollRevealRef.current.init();
      // this.init();
      // console.log('Route changed:', this.props.history);
    }
  }

  render() {
    return (
      <BrowserRouter>
        <ScrollReveal
          ref="scrollReveal"
           children={() => (
              <Switch>
                <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
                <AppRoute exact path="/launchpad" component={Curriculum} layout={LayoutAlternative} />
                <AppRoute exact path="/research" component={Symposium} layout={LayoutAlternative} />
                <AppRoute exact path="/testimonials" component={pr} layout={LayoutDefault} />
                <AppRoute exact path="/secondary" component={Secondary} layout={LayoutDefault} />
                <AppRoute exact path="/team" component={Team} layout={LayoutDefault} />

                {/* <AppRoute path="/decision/:userId" component={Acceptance} layout={LayoutAlt2}/> */}
                {/* <AppRoute path="/letter/:userId" component={RejectResearch} layout={LayoutAlt2}/>
                <AppRoute path="/update/:userId" component={AcceptanceBootcamp} layout={LayoutAlt2}/> */}

                <Route exact path="/temp">
                  <Login onLogin={this.onLogin} />
                </Route>

                <AppRoute exact path="/apply-research" component={ResearchApplication} layout={LayoutPlain} />
                <AppRoute exact path="/apply" component={ProgramRouter} layout={LayoutPlainHeader} />
                <AppRoute exact path="/apply-launchpad" component={LaunchpadApplication} layout={LayoutPlain} />

                <AppRoute path="/update/:userId" component={AcceptanceLaunchpad} layout={LayoutAlt2}/>
                <AppRoute path="/letter/:userId" component={AcceptanceResearch} layout={LayoutAlt2}/>
                <AppRoute path="/decision/:userId" component={RejectResearch} layout={LayoutAlt2}/>
                <AppRoute path="/commit/:userId" component={ResearchAcceptWithPayment} layout={LayoutAlt2}/>

                <Route path='/apply-fa-23' component={() => {
                  window.location.href = '/apply';
                  return null;
                }}/>
                <Route path='/applyfall23' component={() => {
                  window.location.href = '/apply';
                  return null;
                }}/>

                
                <Route path='/bootcamp-application' component={() => {
                  window.location.href = 'https://forms.gle/aX4j2ZZfU1Q4b9pN8';
                  return null;
                }}/>
                <Route path='/research-application' component={() => {
                  window.location.href = 'apply';
                  return null;
                }}/>
                <Route path='/hiring' component={() => {
                  window.location.href = 'https://airtable.com/appjqjUChqruvhQaS/pag2uWtHvW71PTUHx/form';
                  return null;
                }}/>
                <Route path='/blastoff-2023-accept' component={() => {
                  window.location.href = 'https://wnewxg7rxr5.typeform.com/to/It50iMMJ';
                  return null;
                }}/>
                <Route path='/financial-aid-application' component={() => {
                  window.location.href = 'https://forms.gle/ppZKduUegpyjbHgGA';
                  return null;
                }}/>
                <Route path='/apply-to-blast' component={() => {
                  window.location.href = 'apply';
                  return null;
                }}/>
              </Switch>
          )} />
        </BrowserRouter>
    );
  }
}

export default withRouter(props => <App {...props} />);
