import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class FeaturesSplit extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-split section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-split-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    );

    const sectionHeader = {
      title: 'Meet the team',
      // paragraph: "BLAST AI was started to unlock ambition and impact in students around the world. Everything BLAST does is centered around giving real trust to students - to tackle important problems in science."
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={splitClasses}>


              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                    The BLAST AI Journey
                  </h3>
                  <p className="m-0 reveal-from-bottom" data-reveal-delay="100" data-reveal-container=".split-item">
                    {/* BLAST Research is an intensive program for motivated and talented individuals across the world. During the 12-week program, you will conduct original AI research with a team of like-minded individuals. This is your chance to contribute to real research by inventing novel architectures, rethinking action-loop approaches, or designing important benchmarks. You will be guided by experienced mentors and you'll present at a program-wide symposium. Many BLAST students ultimately publish their work at prestigious PhD-level conferences. */}
                    BLAST AI was founded to unlock ambition and impact in students around the world. Everything BLAST does is centered around giving real trust to students - to tackle important problems in science.
                  </p>
                  <br/>
                  <p className="m-0 reveal-from-bottom" data-reveal-delay="100" data-reveal-container=".split-item">
                    {/* BLAST Research is an intensive program for motivated and talented individuals across the world. During the 12-week program, you will conduct original AI research with a team of like-minded individuals. This is your chance to contribute to real research by inventing novel architectures, rethinking action-loop approaches, or designing important benchmarks. You will be guided by experienced mentors and you'll present at a program-wide symposium. Many BLAST students ultimately publish their work at prestigious PhD-level conferences. */}
                    Our mentors and directors have founded startups, worked at companies like Meta, Amazon, and Citadel, and conducted research at top labs like BAIR and Stanford's Fire Lab. We know how to help students conduct immpactful research.
                  </p>
                  <br/>
                  <p className="m-0 reveal-from-bottom" data-reveal-delay="100" data-reveal-container=".split-item">
                    {/* BLAST Research is an intensive program for motivated and talented individuals across the world. During the 12-week program, you will conduct original AI research with a team of like-minded individuals. This is your chance to contribute to real research by inventing novel architectures, rethinking action-loop approaches, or designing important benchmarks. You will be guided by experienced mentors and you'll present at a program-wide symposium. Many BLAST students ultimately publish their work at prestigious PhD-level conferences. */}
                    Most importantly, we believe in equity in research and education. COPYWRITE
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-scale-up',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image
                    className="has-shadow"
                    src={require('./../../assets/images/ppl1.jpg')}
                    alt="Features split 02"
                    width={528}
                    height={396} />
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;