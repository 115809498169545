import React from 'react';
import Image from '../elements/Image';

const MapWithStaticImage = () => {
  const sectionHeader = {
    title: 'Our Global Student Community',
    paragraph: 'BLAST AI students come from all around the world.'
  };

  return (
    <section className=" py-12">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold">{sectionHeader.title}</h2>
          <p className="text-xl text-gray-600">{sectionHeader.paragraph}</p>
        </div>
        <div className="flex justify-center">
        <Image
            className="max-w-full" 
            src={require(`./../../assets/images/blast map.png`)}
            alt="BLAST Map"
          />
        </div>
      </div>
    </section>
    // <p>ialsdjl</p>
  );
};

export default MapWithStaticImage;