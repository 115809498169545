import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';

import rocketImage from '../../assets/images/robot-rocket.png';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class HeroSplit extends React.Component {

  state = {
    videoModalActive: false
  }  

  openVideoModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: true });
  }

  closeVideoModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: false });
  }  

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const heroBackground = document.querySelector('.hero-background');
    const offset = window.pageYOffset;
    if (heroBackground) {
      heroBackground.style.backgroundPositionY = `${offset * -.5}px`;
    }

    
    const rocket = document.querySelector('.rocket');
    const exhaust = document.querySelector('.rocket-exhaust');
    if (rocket) {
      rocket.style.transform = `translateY(${-1.5 * offset}px)`;
    }
    if (exhaust) {
      exhaust.style.transform = `translateY(${-1.5 * offset - 20}px)`; // Adjust -20px or as needed for exhaust position
    }
  }

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'hero section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'hero-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider',
    );

    const splitClasses = classNames(
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    );

    return (
      <div className="hero-background">
        {<section
          {...props}
          className={outerClasses}
        >
          <div className="hero-container">
            <div className={innerClasses}>
              <div className={splitClasses}>
                <div className="hero-content center-content-mobile reveal-from-top width=100%">
                  <h1 className="mt-15 mb-16">
                    BLAST AI
                  </h1>
                  <p className="mt-0 mb-16">
                    An intensive online AI research program for talented high school students. Applications are reviewed on a rolling basis.
                  </p>
                  <ButtonGroup>
                    <Button tag="a" className="hero-button" color="primary" href="/apply" wideMobile>
                      BLAST AI Applications
                    </Button>
                    {/* <Button tag="a" className="hero-button" color="primary" href="https://forms.gle/vGT2RJGPHYUAmu44A" wideMobile>
                      Bootcamp Application
                    </Button>                     */}
                  </ButtonGroup>
                  <p className="mt-32 mb-16">
                    Explore the exciting and novel projects completed by BLAST students in past cohorts!
                  </p>
                  <ButtonGroup>
                    <Button tag="a" className="hero-button" color="dark" href="/research" wideMobile>
                      Read Student Publications
                    </Button>                    
                  </ButtonGroup>
                  {/* <h6 className="mt-15 mb-0">
                    Developed by researchers from
                  </h6>
                  <Image 
                    src={require('../../assets/images/colleges.jpg')} 
                    class=""
                  /> */}
                </div>
              </div>
            </div>
          </div>  
        </section>}
        <div className="rocket-container">
          <img src={rocketImage} className="rocket" alt="Rocket" />
        </div>
      </div>
    );
  }
}

HeroSplit.propTypes = propTypes;
HeroSplit.defaultProps = defaultProps;

export default HeroSplit;