import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import Slider from 'react-slick';
import { SectionSplitProps } from '../../utils/SectionProps';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class Testimonial extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'testimonial section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'testimonial-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: 'Learn what our past students have to say about BLAST',
      paragraph: 'Working in small groups on a research problem allows students to become experts in their field while forming life-long relationships and having fun!'
    };

    const testimonials = [
      {
        name: 'Nishkal Hundia',
        location: 'India',
        title: 'Genomic Sequencing AI',
        text: 'I worked on a novel algorithm that predicts missing values in DNA sequences. The research segment helped me develop leadership skills as I had to coordinate constantly with my group in order to finish my project in the six weeks. All the mentors were very helpful and were always there to help us out at any point.'
      },
      {
        name: 'Alexandra Koster',
        location: 'NYC',
        title: 'Political Polling AI',
        text: 'We used natural language processing to use political tweets to make predictions on localized policy based elections. The research segment really helped me grow because I got to realize the real world application of AI. There was never a time when I was bored, whether during the lectures or doing the research.'
      },
      {
        name: 'Soham Vohra',
        location: 'California',
        title: 'Crypto Price Prediction AI',
        text: 'My research project used stance detection on tweets to predict Bitcoin price movement. The research segment definitely helped me grow by learning to read academic works better. If there\'s one word that I could use to describe BLAST, it would definitely be collaborative.'
      }
    ];
    
    const testimonials2 = [
      {
        name: 'Abhinav Jain',
        title: 'Smart Contract Correction LLms',
        text: 'The bootcamp is a lot of fun because you are learning something new each day. It is a bit surreal to move so fast and learn things I wouldn\'t have known until my first or second year of college. There were so many people in the bootcamp who were really talented, which inspired me to work harder. The program made me certain that AI was something I wanted to pursue in the future.'
      },
      {
        name: 'Nishkal Hundia',
        title: 'Genomic Sequencing AI',
        text: 'I worked on a novel algorithm that predicts missing values in DNA sequences. The research segment helped me develop leadership skills as I had to coordinate constantly with my group in order to finish my project in the six weeks. All the mentors were very helpful and were always there to help us out at any point.'
      },
      {
        name: 'Soham Vohra',
        title: 'Crypto Price Prediction AI',
        text: 'My research project used stance detection on tweets to predict Bitcoin price movement. The research segment definitely helped me grow by learning to read academic works better. If there\'s one word that I could use to describe BLAST, it would definitely be collaborative.'
      },
      {
        name: 'Badis Labbedi',
        title: 'Contextual Physics LLMs',
        text: 'It\'s very challenging but definitely worth the hussle. Learning ML basics, meeting like-minded peers, and working on your own research in a brilliant team isn\'t an experience that you get to live in every program out there.'
      },
      {
        name: 'Paul Zhou',
        title: 'NLP Code Alignment',
        text: 'The bootcamp was my first introduction to the inner workings of AI. The fast paced lectures and high work load were both challenging and rewarding. If you want to learn AI quickly under the mentorship of very capable people, I would recommend blast.'
      },
      {
        name: 'Neel Nigam',
        title: 'NLP Code Alignment',
        text: 'My experience in BLAST AI was informative and challenging. I found that the lectures went in-depth and were explained well. I was challenged many times by the homework and projects, but was able to succeed with help from the mentors and other students. I highly recommend joining BLAST AI. It is an interesting, enlightening, and challenging experience.'
      },
      {
        name: 'Alexandra Koster',
        title: 'Political Polling AI',
        text: 'We used natural language processing to use political tweets to make predictions on localized policy based elections. The research segment really helped me grow because I got to realize the real world application of AI. There was never a time when I was bored, whether during the lectures or doing the research.'
      },
      {
        name: 'Anton Kokoletsos',
        title: 'Computer Vision Bias Reduction',
        text: 'I was greatly challenged during the bootcamp. I felt motivated to continue working with AI because I am passionate about it and it seems like it has many good applications. I also felt inspired by the mentors as many of them are nice and helped out, and are also very successful in the world of AI. I definitely recommend that they do Blast AI for next year.'
      },
      {
        name: 'Juan Belza Garcia',
        title: 'LLMs for Website Accessibility Repairs',
        text: 'The bootcamp was intensive and fun. Though I was quite familiar with most topics, this was still a great learning experience and I could not stress enough how effective the bootcamp was in covering so many topics in such little time. I was motivated to complete the bootcamp by the prospect of conducting graduate-level research.'
      },
      {
        name: 'Chirag Iyer',
        text: 'The bootcamp was an amazing experience for me. I learned a lot of new skills and concepts in AI, and I had the opportunity to work on real-world projects with other passionate learners. I was challenged every day, but also inspired by the instructors and mentors who guided me through the process. BLAST AI is not just a bootcamp, but a community.',
        title: 'Molecular LLM'
      },
    ]
    var settings = {
      // dots: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: '60px',
      slidesToShow: 3,
      focusOnSelect: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            centerPadding: '40px',
          }
        }
      ]
    };
    return (
      <section
        {...props}
        className={outerClasses}
      >
        
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} tag="h1" className="center-content invert-color" />
              {/* <div className={tilesClasses}> */}
              <Slider {...settings}>
                {testimonials2.map((testimonial, index) => (
                  <div key={index} className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap">
                    <div className="tiles-item-inner has-shadow">
                      <div className="testimonial-item-header mb-16">
                        <div className="testimonial-item-image">
                          <span className="testimonial-item-link fw-600">
                            <a href="#0">{testimonial.title}</a>
                          </span>
                        </div>
                      </div>
                      <div className="testimonial-item-content">
                        <p className="text-sm mb-0">
                          {testimonial.text}
                        </p>
                      </div>
                      <div className="testimonial-item-footer text-xs fw-500 mt-32 mb-0 has-top-divider">
                        <span className="testimonial-item-name">
                          {testimonial.name}
                        </span>
                        {/* <span className="text-color-low"> / </span> */}
                        {/* <span className="testimonial-item-link fw-600">
                          <a>{testimonial.location}</a>
                        </span> */}
                      </div>
                    </div>
                  </div>
                ))}
                </Slider>
              {/* </div> */}
          </div>
        </div>
      </section>
    );
  }
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;




// import React from 'react';
// import Slider from 'react-slick';
// import { SectionSplitProps } from '../../utils/SectionProps';
// import SectionHeader from './partials/SectionHeader';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import classNames from 'classnames';
// import Button from '../elements/Button';
// import Image from '../elements/Image';
// import './carouselStyles.css';

// const data2 = [{
//   image: 'minecraft.jpg',
//   title: 'Reinforcement learning with LLM Agents',
//   description: 'Simulating Reinforcment Learning with LLM Skill Libraries – Crafter',
//   bullets: [
//     'Embedded natural language skill libraries for LLMs to select actions and read rewards',
//     'Analyzed patient transcripts to identify medical specialties, saving nurse time',
//     'Highly applicable to robotics simulations',
//   ],
//   paperLink: 'pending',
// },
// {
//     title: 'Genomic Imputation AI',
//     description: 'Genotype Imputation Using K-Nearest Neighbors and Levenshtein Distance Metric',
//     bullets: [
//       'Imputed missing genomic sequences in C Elegans using the Levenshtein Distance',
//       'Used novel NLP strategies to boost accuracy from 30% to 86% – beat the state of the art accuracy',
//       'Awarded US congressional recognition',
//     ],
//     paperLink: 'https://ieeexplore.ieee.org/document/9952611',
//     image: 'genotype-symp.png',
// },
// {
//   image: 'healthcare.png',
//   title: 'Specialty Identification through Clinical Text',
//   description: 'Machine Learning in Clinical Text: Specialty Identification and COVID-19 Risk',
//   bullets: [
//     'Analyzed patient transcripts to identify medical specialties, saving nurse time',
//     'Used novel keyword extraction and LLM strategies',
//     'Also assessed COVID-19 risk with 99% accuracy',
//   ],
//   paperLink: 'https://ieeexplore.ieee.org/document/9952512',
// },

// // {
// //   image: 'galaxies.jpg',
// //   title: 'Anomaly',
// //   description: 'Utilizing Computer Vision Algorithms to Detect Contrails',
// //   bullets: [
// //     'Analyzed patient transcripts to identify medical specialties, saving nurse time',
// //     'Used novel keyword extraction and LLM strategies',
// //     'Published in the top conference for aeronautics',
// //   ],
// //   paperLink: 'pending',
// // },
// {
//   title: 'Political Tweet Stance Detection',
//   description: 'Stance Detection of Political Tweets with Transformer Architectures',
//   bullets: [
//     'Detected stance in mass political tweets, simulating expensive polls',
//     'Scraped Twitter and used LLMs for stance detection',
//     'Achieved state of the art accuracy',
//   ],
//   paperLink: 'https://ieeexplore.ieee.org/document/9952951',
//   image: 'polling.png',
// },
// ];

// const researchHighlights = data2.map(item => ({
//   id: item.title, // Assuming titles are unique, otherwise use a unique identifier
//   // image: src={require(`./../../assets/images/${item.image}`)},
//   imageUrl: require(`./../../assets/images/${item.image}`),
//   title: item.title,
//   abstract: item.description,
//   bullets: item.bullets,
//   paperLink: item.paperLink === 'pending' ? "https://google.com" : item.paperLink // If the paper link is 'pending', set it to null
// }));


// export default function ResearchHighlightsCarousel() {
//   var settings = {
//     // dots: true,
//     infinite: true,
//     speed: 1000,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     centerMode: true,
//     centerPadding: '60px',
//     slidesToShow: 3,
//     focusOnSelect: true,
//     responsive: [
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 1,
//           centerPadding: '40px',
//         }
//       }
//     ]
//   };
  
//   return (
//     <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
//       <div className="mb-8">
//         <h2 className="text-2xl font-bold text-center mb-4">Research Highlights</h2>
//         <p className="text-lg text-center mb-6">Explore the latest research from our students and faculty.</p>
//       </div>
//       <Slider {...settings}>
        
//         {researchHighlights.map((highlight) => (
//           <div key={highlight.id} className="outline-none">
//             <div className="p-4">
//               <img src={highlight.imageUrl} alt={highlight.title} className="mx-auto mb-4" />
//               <h3 className="text-lg font-bold">{highlight.title}</h3>
//               <p>{highlight.abstract}</p>
//             </div>
//           </div>
//         ))}
//       </Slider>
//       <div className="text-center mt-8">
//         <button color="primary" className="mt-4 px-6 py-2 border rounded-md hover:bg-gray-100 transition duration-300">
//           Learn More
//         </button>
//         {/* <Button tag="a" color="primary" href='/research' wideMobile>
//           Learn More
//         </Button> */}
//       </div>
//     </div>
//   );
// }
