import React from 'react';
import classNames from 'classnames';
import Confetti from 'react-confetti';
import GenericSection from '../components/sections/GenericSection';
import Button from '../components/elements/Button';

class AcceptancePage extends React.Component {
  state = {
    showConfetti: false,
    name: ""
  };

  async componentDidMount() {
    const { userId } = this.props.match.params;
    let curr = await this.checkValidUUID(userId);
    this.setState({ showConfetti: true , name:curr});
    // console.log("CURR", curr)
  }

  async checkValidUUID(uuidString) {
    let uid = uuidString.replace(".","");
    let url = "https://api.airtable.com/v0/apprKYm0xR4aMxjHB/tblfdMlSS4zM5wHwM";
    var options = {
      method: 'GET',
      headers: {
          Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_KEY}`
      }
    };
    var name = ""
    try {
      let fetching = true;
      while (fetching) {
        let response = await fetch(url, options);
        let data = await response.json();
        const records = data.records;
        records.map(record => {
          if (record.fields.UUID === uid) {
            name = record.fields.name;
            fetching = true;
            return name
          } 
        })
        if (name != ""){
          return name
        }
        if (data.offset != "") {
            url += `?offset=${data.offset}`;
        } else {
            fetching = false;
        }
      }
    } catch (error) {
      this.props.history.push('/');
      console.log("ERROR:", error);
    }
    this.props.history.push('/');
    return name
  }

  capitalizeWords(inputString) {
    if (!inputString) {
      return "";
    }
  
    const words = inputString.split(" ");
    const ret = words.map(word => {
      const lowercase = word.toLowerCase();
      const firstLetter = lowercase.charAt(0).toUpperCase();
      return `${firstLetter}${lowercase.slice(1)}`;
    });
  
    return ret.join(" ");
  }

  decryptAndCapitalize(encryptedName) {
    console.log(encryptedName);
    // encryptedName = encryptedName.userId;
    if (!encryptedName || !this.checkValid(encryptedName)) {
      // Route home
      // this.props.history.push('/');
      console.log('pushed');
    }

    const decryptionKey = 11;
    const modSpace = 26;

    const decryptedName = encryptedName
      .split('')
      .map((char) => {
        return this.decryptId(char, decryptionKey, modSpace);
      })
      .join('');

    // Capitalize the first character
    var res = decryptedName.charAt(0).toUpperCase() + decryptedName.slice(1);
    res = res.substring(1, res.length - 1);
    console.log(res);
    res = this.capitalizeWords(res);
    return res;
  }

  checkValid(inputString) {
    /** checkValid(inputString)
     * Checks if the first character in the input is Z and if the last input is A
     */
    if (!inputString) {
      return false;
    }
    if (inputString.charAt(0) == 'Z' && inputString.charAt(inputString.length - 1) == 'A') {
      return true;
    }
    return false;
  }

  decryptId(inputChar, key, modSpace) {
    /** decryptId(inputChar, key, modSpace)
     * Subtracts the key (default 11) and takes mod 26 of it
     */
    if (inputChar == "-") {
      return ' '
    }

    const charCode = inputChar.charCodeAt(0) - 65; // Should range from 0 to 25 if correct
    const res = (charCode - key + modSpace) % modSpace;
    return String.fromCharCode(res + 'A'.charCodeAt(0));
  }
  
  render() {
    const { userId } =  this.props.match.params;

    
    // Converting userId into the actual name
    // Input: all upercase letters, rotated 11
    // Output: rotate backwards 11
    

    const { showConfetti, name } = this.state;
    var { fullName } = "";
    // console.log(name)
    // fullName = this.decryptAndCapitalize(userId);
    fullName = name;

    if (fullName !== "") {
      return (
        <div className="container mt-20 mx-auto px-4 md:px-0">
          <section className="flex justify-center items-center min-h-screen mt-20">
            <div className="bg-white border border-gray-400 p-8 md:p-16 pt-64 md:pt-64 md:pl-32 md:pr-32 mx-auto max-w-3xl text-left">
              {showConfetti && (
                <Confetti
                  recycle={false}
                  numberOfPieces={800}
                  width={window.innerWidth}
                  height={window.innerHeight}
                />
              )}
              <div className="pt-50">
                <h2 className="px-4 md:px-20 pb-32 pt-10">Congratulations {fullName}!</h2>
              </div>
              <p className="px-4 md:px-20 text-sm md:text-base">
                    We are honored to accept you to the BLAST AI Summer 2024 Launchpad Cohort!
                    <br />
                    <br />
                    We are absolutely convinced that you possess the intellectual vitality required to build game-changing AI projects. You are an exceptional match for BLAST, and we sincerely hope you will join us this summer! 
                    <br />
                    <br />
                    The Launchpad Program is a comprehensive and intensive program designed to accelerate your growth in the AI field. During the program, you will learn everything from tree-based classifiers to state-of-the-art ML algorithms, including transformers, latent diffusion, and proximal policy optimization. Additionally, you will complete a suite of impactful projects that include a chatbot, a text-conditioned image generator, and an RL video game agent! By the end of the program, you will have developed an impressive machine learning portfolio and a plethora of valuable skills (e.g. Pytorch, CUDA, efficient hyperparameter tuning, etc.).
                    <br />
                    <br />
                    On this page you can find: (1) a copy of the program overview, which outlines the schedule for the program as well as the cost of attendance; and (2) a Terms of Service agreement.
                    <br />
                    <br />
                    BLAST is dedicated to expanding access to AI research, which is why we price the program far less than comparable programs. If you applied for financial aid on your application, you should receive an update within this upcoming week. Please keep in mind that we are only considering financial need at this time for scholarship eligibility.
                    <br />
                    <br />
                    Next Steps:
                    <br />
                    <ol className="list-decimal pl-6 mb-4">
                    <li>
                      First, we have placed you in our program beginning on 7/29 and ending on 8/9. We currently do not have remaining capacity in our other two Launchpad programs. If you decide to commit, please note that you are confirming your availability from 7/29 to 8/9.
                    </li>                    
                    <li>
                      Please fill out {' '}
                      <a
                        href="https://form.jotform.com/240911528157153"
                      >
                        this form
                      </a>{' '}
                      with your decision to commit before 7/25/2024.
                    </li>                    
                    <li>
                      Unfortunately, we are very low on financial aid. We are only reserving scholarships for rare cases. Please fill out this form {' '}
                      <a
                        href="https://form.jotform.com/240911528157153"
                      >
                        this form
                      </a>{' '}
                      to let us know you would like aid.
                    </li>
                      <li>
                        Read through the documents on this page completely.
                      </li>
                    </ol>
                    <br />
                    You may pay your tuition fees in the form above. If you prefer an invoice, payment by check, or any other payment options, please reply to your status update email.
                    <br />
                    <br />
                    Once again, congratulations! We are excited to have the opportunity to guide you through your journey of learning and exploration this summer!
              </p>
            </div>
          </section>

          <GenericSection className="center-content">
              <div className="container">
                <div className="flex justify-center">
                  <Button
                    color="primary" 
                    target="_blank" 
                    href="https://blastai.org/launchpad-overview.pdf" 
                    wideMobile
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open('https://blastai.org/launchpad-overview.pdf', '_blank', 'noopener noreferrer');
                    }}
                    className="mr-32"
                  >
                    Program Overview
                  </Button>
                  
                  <Button 
                    color="primary" 
                    target="_blank" 
                    href="https://blastai.org/tos.pdf" 
                    wideMobile
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open('https://blastai.org/tos.pdf', '_blank', 'noopener noreferrer');
                    }}
                  >
                    Terms of Service
                  </Button>
                </div>
              </div>
            </GenericSection>

        </div>
      );
    } else {
      // show loading
      return (
          <div className="container mt-20 mx-auto px-4 md:px-0">
              <section className="flex justify-center items-center min-h-screen mt-20">
              <div className="p-8 md:p-16 pt-64 md:pt-64 md:pl-32 md:pr-32 mx-auto max-w-3xl text-center">
                  <p>Loading...</p>
              </div>
              </section>
          </div>
      );
    }
  }
}

export default AcceptancePage;