import React from 'react';
import Slider from 'react-slick';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import classNames from 'classnames';
import Button from '../elements/Button';
import Image from '../elements/Image';
import './carouselStyles.css';

const data2 = [{
  image: 'minecraft.jpg',
  title: 'Reinforcement learning with LLM Agents',
  description: 'Simulating Reinforcment Learning with LLM Skill Libraries – Crafter',
  bullets: [
    'Embedded natural language skill libraries for LLMs to select actions and read rewards',
    'Analyzed patient transcripts to identify medical specialties, saving nurse time',
    'Highly applicable to robotics simulations',
  ],
  paperLink: 'pending',
},
{
    title: 'Genomic Imputation AI',
    description: 'Genotype Imputation Using K-Nearest Neighbors and Levenshtein Distance Metric',
    bullets: [
      'Imputed missing genomic sequences in C Elegans using the Levenshtein Distance',
      'Used novel NLP strategies to boost accuracy from 30% to 86% – beat the state of the art accuracy',
      'Awarded US congressional recognition',
    ],
    paperLink: 'https://ieeexplore.ieee.org/document/9952611',
    image: 'genotype-symp.jpg',
},
{
  image: 'healthcare.jpg',
  title: 'Specialty Identification through Clinical Text',
  description: 'Machine Learning in Clinical Text: Specialty Identification and COVID-19 Risk',
  bullets: [
    'Analyzed patient transcripts to identify medical specialties, saving nurse time',
    'Used novel keyword extraction and LLM strategies',
    'Also assessed COVID-19 risk with 99% accuracy',
  ],
  paperLink: 'https://ieeexplore.ieee.org/document/9952512',
},

// {
//   image: 'galaxies.jpg',
//   title: 'Anomaly',
//   description: 'Utilizing Computer Vision Algorithms to Detect Contrails',
//   bullets: [
//     'Analyzed patient transcripts to identify medical specialties, saving nurse time',
//     'Used novel keyword extraction and LLM strategies',
//     'Published in the top conference for aeronautics',
//   ],
//   paperLink: 'pending',
// },
{
  title: 'Political Tweet Stance Detection',
  description: 'Stance Detection of Political Tweets with Transformer Architectures',
  bullets: [
    'Detected stance in mass political tweets, simulating expensive polls',
    'Scraped Twitter and used LLMs for stance detection',
    'Achieved state of the art accuracy',
  ],
  paperLink: 'https://ieeexplore.ieee.org/document/9952951',
  image: 'polling.jpg',
},
];

const researchHighlights = data2.map(item => ({
  id: item.title, // Assuming titles are unique, otherwise use a unique identifier
  // image: src={require(`./../../assets/images/${item.image}`)},
  imageUrl: require(`./../../assets/images/${item.image}`),
  title: item.title,
  abstract: item.description,
  bullets: item.bullets,
  paperLink: item.paperLink === 'pending' ? "https://google.com" : item.paperLink // If the paper link is 'pending', set it to null
}));


export default function ResearchHighlightsCarousel() {
  var settings = {
    // dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '60px',
    slidesToShow: 3,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerPadding: '40px',
        }
      }
    ]
  };
  
  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="mb-8">
        <h2 className="text-2xl font-bold text-center mb-4">Research Highlights</h2>
        <p className="text-lg text-center mb-6">Explore the latest research from our students and faculty.</p>
      </div>
      <Slider {...settings}>
        
        {researchHighlights.map((highlight) => (
          <div key={highlight.id} className="outline-none">
            <div className="p-4">
              <img src={highlight.imageUrl} alt={highlight.title} className="mx-auto mb-4" />
              <h3 className="text-lg font-bold">{highlight.title}</h3>
              <p>{highlight.abstract}</p>
            </div>
          </div>
        ))}
      </Slider>
      <div className="text-center mt-8">
        <button color="primary" className="mt-4 px-6 py-2 border rounded-md hover:bg-gray-100 transition duration-300">
          Learn More
        </button>
        {/* <Button tag="a" color="primary" href='/research' wideMobile>
          Learn More
        </Button> */}
      </div>
    </div>
  );
}
