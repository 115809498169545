import React, { useState, useEffect, useRef } from 'react';

const stats = [
  { id: 0, name: 'Research Papers', value: 20, speed: 2 },
  { id: 1, name: 'Represented High Schools', value: 140, speed: 14 },
  { id: 2, name: 'Represented Countries', value: 30, speed: 3 },
];

export default function Numbers() {
  const [counters, setCounters] = useState(stats.map(stat => ({ ...stat, currentValue: 0, isVisible: false })));
  const counterRefs = useRef(counters.map(() => React.createRef()));

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const id = entry.target.getAttribute('data-id');
        const isVisible = entry.isIntersecting;
        setCounters(currentCounters =>
          currentCounters.map(counter => {
            if (String(counter.id) === id) {
              return { ...counter, isVisible: isVisible };
            }
            return counter;
          })
        );
      });
    }, { threshold: 0.1 });

    const currentCounters = counterRefs.current;
    currentCounters.forEach(ref => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      currentCounters.forEach(ref => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounters(currentCounters =>
        currentCounters.map(counter => {
          if (counter.isVisible && counter.currentValue < counter.value) {
            const nextValue = Math.min(counter.value, counter.currentValue + counter.speed);
            return { ...counter, currentValue: nextValue };
          }
          return counter;
        })
      );
    }, 50);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="bg-white py-10">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
          {counters.map((counter, index) => (
            <div
              key={counter.id}
              className="mx-auto flex max-w-xs flex-col gap-y-4"
              ref={counterRefs.current[index]}
              data-id={counter.id}
            >
              <dt className="text-base leading-7 text-gray-500">{counter.name}</dt>
              <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 md:text-5xl">
                {counter.currentValue}+
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
