import React from 'react';
import ApplicationTimeline from '../components/sections/ApplicationTimeline';
import ResearchSplit from '../components/sections/ResearchSplit';
import ResearchCarousel from '../components/sections/ResearchCarosel2';
// import ResearchHighlightsCarousel from '../components/sections/ResearchCarosel copy';
import FAQ from '../components/sections/FAQ';
import Image from '../components/elements/Image';
import ResearchInfo from '../components/sections/research/ResearchInfo'
import Publications from '../components/sections/research/Publications'

// import BLAST from '../../src/assets/images/BLAST AI Curriculum Schedule'

class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* <Papers className="has-bg-color-cut illustration-section-02"/> */}
        <ResearchInfo hasBgColor invertColor />
        <ResearchCarousel />
        <ResearchSplit />
        <ApplicationTimeline topDivider className="has-bg-color-cut illustration-section-02"/>
        <Publications hasBgColor invertColor />
        <FAQ />
      </React.Fragment>
    );
  }
}

export default Home;