import React from 'react';
import Classes from '../components/sections/Classes';
import Roadmap from '../components/sections/Roadmap';
import Pricing from '../components/sections/Pricing';
import Cta from '../components/sections/Cta';
import GenericSection from '../components/sections/GenericSection';
import Button from '../components/elements/Button';
import SectionHeader from '../components/sections/partials/SectionHeader';
import ApplicationTimeline from '../components/sections/ApplicationTimeline';
import BootcampSplit from '../components/sections/bootcamp/BootcampSplit';
import FeaturesTabs from '../components/sections/FeaturesTabs';
import ProjectsCarousel from '../components/sections/bootcamp/ProjectsCarousel';
import CurrSchedule from '../components/sections/bootcamp/CurrSchedule';
import Tracks from '../components/sections/bootcamp/Tracks';
import FAQ from '../components/sections/FAQ';
import FeaturesSplit from '../components/sections/FeaturesSplit';


class Home extends React.Component {
    // const sectionHeader2 = {
    //   title: 'Carefully crafted curriculum',
    //   paragraph: 'Our lesson plans are based on years of hosting workshops and running ML courses with the feedback and experience of over 25k students.'
    // };

    // begin with basics, end with mastery
  render() {
    return (
      <React.Fragment>
        {/* <Classes  className="has-bg-color-cut illustration-section-02"/> */}
        <BootcampSplit hasBgColor invertColor />
        <Tracks />
        {/* <ProjectsCarousel /> */}
        <FeaturesSplit />
        
        <FeaturesTabs />
        {/* <Tiles /> */}
        {/* <Roadmap topdivider className="has-bg-color-cut illustration-section-02"/> */ }
        {/* <Pricing hasBgColor pricingSlider />
        <Cta hasBgColor invertColor split id="apply"/> */}
        <FAQ />
      </React.Fragment>
    );
  }
}

export default Home;