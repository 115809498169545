import React, { Component, useEffect } from 'react';
import HeroSplit from '../components/sections/HeroSplit';
import ResearchCarosel from '../components/sections/ResearchCarosel';
import ResearchCarosel2 from '../components/sections/ResearchCarosel2';
import FeaturesTabs from '../components/sections/FeaturesTabs';
import Board from '../components/sections/Board';
import Mentors from '../components/sections/MentorHighlight';
import FAQ from '../components/sections/FAQ';
import ProgramOfferings from '../components/sections/ProgramOfferings';
import Cta from '../components/sections/Cta';
import Numbers from '../components/sections/Numbers';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import GenericSection from '../components/sections/GenericSection';
import Accordion from '../components/elements/Accordion';
import AccordionItem from '../components/elements/AccordionItem';
import SectionHeader from '../components/sections/partials/SectionHeader';
import Map from '../components/sections/Map';
import Mission from '../components/sections/MissionStatement'
//import Stats from '../components/sections/Stats'
// import loadGoogleTagScript from '../utils/googleTag'

import { Widget } from '@typeform/embed-react'

class Home extends React.Component {
  
  render() {
    const faqHeader = {
      title: 'Frequently asked questions'
    }  
  
    return (
      <React.Fragment>
        <HeroSplit hasBgColor invertColor />
        <Numbers />
        {/* <Stats /> */}
        <Mission id="About" />
        {/* <ResearchCarosel bottomOuterDivider /> */}
        <ResearchCarosel2 />
        <ProgramOfferings />
        {/* <ResearchCarosel bottomOuterDivider /> */}
        <FeaturesSplit id="About" />
        <div style={{display: 'flex',  justifyContent:'center', width: '100%'}} class="pt-8">
          <Widget id="eta8tr1k" style={{ width: '100%', height: '515px' }} className="my-form" />
        </div>
        <Mentors />
        {/* <ResearchCarosel bottomOuterDivider /> */}
        {/* <Board className="illustration-section-01" /> */}
        <Map />
        <FAQ />
      </React.Fragment>
    );
  }
}

export default Home;