import React from 'react';
import classNames from 'classnames';
import Confetti from 'react-confetti';
import GenericSection from '../components/sections/GenericSection';
import Button from '../components/elements/Button';

class AcceptancePage extends React.Component {
  state = {
    showConfetti: false,
  };

  componentDidMount() {
    this.setState({ showConfetti: true });
  }

  capitalizeWords(inputString) {
    if (!inputString) {
      return "";
    }
  
    const words = inputString.split(" ");
    const ret = words.map(word => {
      const lowercase = word.toLowerCase();
      const firstLetter = lowercase.charAt(0).toUpperCase();
      return `${firstLetter}${lowercase.slice(1)}`;
    });
  
    return ret.join(" ");
  }

  decryptAndCapitalize(encryptedName) {
    console.log(encryptedName);
    // encryptedName = encryptedName.userId;
    if (!encryptedName || !this.checkValid(encryptedName)) {
      // Route home
      this.props.history.push('/');
      console.log('pushed');
    }

    const decryptionKey = 11;
    const modSpace = 26;

    const decryptedName = encryptedName
      .split('')
      .map((char) => {
        return this.decryptId(char, decryptionKey, modSpace);
      })
      .join('');

    // Capitalize the first character
    var res = decryptedName.charAt(0).toUpperCase() + decryptedName.slice(1);
    res = res.substring(1, res.length - 1);
    console.log(res);
    res = this.capitalizeWords(res);
    return res;
  }

  checkValid(inputString) {
    /** checkValid(inputString)
     * Checks if the first character in the input is Z and if the last input is A
     */
    if (!inputString) {
      return false;
    }
    if (inputString.charAt(0) == 'A' && inputString.charAt(inputString.length - 1) == 'Z') {
      return true;
    }
    return false;
  }

  decryptId(inputChar, key, modSpace) {
    /** decryptId(inputChar, key, modSpace)
     * Subtracts the key (default 11) and takes mod 26 of it
     */
    if (inputChar == "-") {
      return ' '
    }

    const charCode = inputChar.charCodeAt(0) - 65; // Should range from 0 to 25 if correct
    const res = (charCode - key + modSpace) % modSpace;
    return String.fromCharCode(res + 'A'.charCodeAt(0));
  }
  
  render() {
    const { userId } =  this.props.match.params;

    
    // Converting userId into the actual name
    // Input: all upercase letters, rotated 11
    // Output: rotate backwards 11
    

    const { showConfetti } = this.state;
    var { fullName } = "";
    fullName = this.decryptAndCapitalize(userId);

return (
  <div className="container mt-20 mx-auto px-4 md:px-0">
    <section className="flex justify-center items-center min-h-screen mt-20">
      <div className="bg-white border border-gray-400 p-8 md:p-16 pt-64 md:pt-64 md:pl-32 md:pr-32 mx-auto max-w-3xl text-center">
        {showConfetti && (
          <Confetti
            recycle={false}
            numberOfPieces={800}
            width={window.innerWidth}
            height={window.innerHeight}
          />
        )}
        <div className="">
          <h2 className="px-4 md:px-20 pb-32 pt-10">Congratulations {fullName}!</h2>
        </div>
        <p className="px-4 md:px-20 text-sm md:text-base">
              Thank you so much for applying to our BLAST AI Fall 2023 Cohort! Your application left our admissions staff inspired by your exceptional intellect and passion, and we are excited to offer you admission to the Fall '23 BLAST AI Bootcamp.
              <br />
              <br />
              The BLAST Bootcamp is a comprehensive and intensive program designed to accelerate your growth in the AI field. During the bootcamp, you will learn everything from tree-based classifiers to state-of-the-art ML algorithms, including transformers, latent diffusion, and proximal policy optimization. Additionally, you will complete a suite of impactful projects including your own chatbot, text-conditioned image generator, and an RL agent that learns to play a game of your choice! By the end of the program, you will have developed an impressive machine learning portfolio and a plethora of valuable skills (e.g. Pytorch, CUDA for GPU parallelism, efficient hyperparameter tuning, etc.). The bootcamp prepares you excellently for AI research, and you will be eligible to transition into our research program during the subsequent spring or summer.
              <br />
              <br />
              We are absolutely convinced that you possess the intellectual vitality required to build game-changing AI projects. You are an exceptional match for BLAST, and we sincerely hope you will join us this fall!
              <br />
              <br />
              On this page you can find: (1) a copy of the program overview, which outlines the schedule for the bootcamp as well as the cost of attendance; and (2) a Terms of Service agreement.
              <br />
              <br />
              BLAST is dedicated to expanding access to AI research, which is why we price the program far less than comparable programs. If you applied for financial aid on your application, you should receive an update within the upcoming week. Please keep in mind that we are only considering financial need at this time for scholarship eligibility.
              <br />
              <br />
              Next Steps:
              <br />
              <ol className="list-decimal pl-6 mb-4">
              <li>
                Please fill out{' '}
                <a
                  href="https://wnewxg7rxr5.typeform.com/to/pXNzxtr1"
                  target="_blank"
                  className="text-blue-500 hover:underline"
                >
                  this form
                </a>{' '}
                with your decision to commit before 9/25/2023.
              </li>

                <li>
                  Read through the documents on this page completely.
                </li>
              </ol>
              <br />
              You may pay your tuition in the form above. If you prefer an invoice, payment by check, or any other payment options, please reply to your status update email.
              <br />
              <br />
              Once again, congratulations! We are excited to have the opportunity to guide you through your journey of learning and exploration this fall!
        </p>
      </div>
    </section>

    <GenericSection className="center-content">
        <div className="container">
          <div className="flex justify-center">
            <Button
              color="primary" 
              target="_blank" 
              href="https://blast-docs.s3.us-east-2.amazonaws.com/BLAST+AI+Bootcamp+Program+Overview+2023.pdf" 
              wideMobile
              type="button"
              onClick={(e) => {
                e.preventDefault();
                window.open('https://blast-docs.s3.us-east-2.amazonaws.com/BLAST+AI+Bootcamp+Program+Overview+2023.pdf', '_blank', 'noopener noreferrer');
              }}
              className="mr-32"
            >
              Program Overview
            </Button>
            
            <Button 
              color="primary" 
              target="_blank" 
              href="https://blastai.org/Blast AI Terms of Service Summer 2023.pdf" 
              wideMobile
              type="button"
              onClick={(e) => {
                e.preventDefault();
                window.open('https://blastai.org/Blast AI Terms of Service Summer 2023.pdf', '_blank', 'noopener noreferrer');
              }}
            >
              Terms of Service
            </Button>
          </div>
        </div>
      </GenericSection>

  </div>
);
}
}

export default AcceptancePage;