import React from 'react';


const LayoutPlain = ({ children }) => (
  <React.Fragment>
    <main className="site-content">
      {children}
    </main>
  </React.Fragment>
);

export default LayoutPlain;  