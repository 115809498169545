import React from 'react';
import Slider from 'react-slick';
import { SectionSplitProps } from '../../../utils/SectionProps';
import SectionHeader from '../partials/SectionHeader';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import classNames from 'classnames';
import Button from '../../elements/Button';
import Image from '../../elements/Image';
import './projects.css';

const data2 = [{
  image: 'minecraft.jpg',
  title: 'Politics',
  description: 'Use LLMs as web agents to simulate polls',
},
{
  image: 'minecraft.jpg',
    title: 'Biology',
    description: 'Use classical ML techniques to optimize genomic imputation tasks',
},
{
  image: 'minecraft.jpg',
    title: 'Healthcare',
    description: 'Create AI nurses to direct patients to specialty providers',
},
{
  image: 'minecraft.jpg',
    title: 'Cybersecurity',
    description: 'Use generative AI to create malware that beats detection algorithms',
},
{
  image: 'healthcare.jpg',
  title: 'Finance',
  description: 'Use multimodal models to predict real estate values',
},
{
  image: 'healthcare.jpg',
  title: 'Astronomy',
  description: 'Use AI to detect anomolous galaxies',
},
];

const researchHighlights = data2.map(item => ({
  id: item.title, // Assuming titles are unique, otherwise use a unique identifier
  description: item.description, // Assuming titles are unique, otherwise use a unique identifier
  // image: src={require(`./../../assets/images/${item.image}`)},
  imageUrl: require(`./../../../assets/images/${item.image}`),
  title: item.title,
  abstract: item.description,
  bullets: item.bullets,
  // paperLink: item.paperLink === 'pending' ? "https://google.com" : item.paperLink // If the paper link is 'pending', set it to null
}));


export default function ResearchHighlightsCarousel() {
  var settings = {
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '60px',
    slidesToShow: 3,
    focusOnSelect: true,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerPadding: '40px',
        }
      }
    ]
  };
  
  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 p-4">
      <div className="mb-8">
        <h2 className="text-2xl font-bold text-center mb-4">Build your ML portfolio</h2>
        <p className="text-lg text-center mb-6">Pick one of our several capstone projects in different specialties.</p>
      </div>
      <Slider {...settings}>
        
        {researchHighlights.map((highlight) => (
          <div key={highlight.id} className="outline-none">
            <div className="p-4">
              <img src={highlight.imageUrl} alt={highlight.title} className="mx-auto mb-4" />
              <h5 className="text-sm font-semibold">{highlight.title}</h5>
              <p class="text-sm">{highlight.description}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}
